.App {
  text-align: center;
}

.title {
  text-align: center;
  display: block;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-item .nav-link p {
  color: dark;
  font-weight: 200;
    padding: 3px;
}
.nav-item .nav-link p:hover {
  background-color: #0b7874;
  color: rgb(245, 235, 235);
  border-radius: 4px;
  padding: 3px;
}

.new-style{
   background-color: #0b7874;
  color: rgb(245, 235, 235);
  border-radius: 4px;
  padding: 3px;
}
.my-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}

.right-table,
.left-table {
  max-width: 100%;
}

body {
  color: #191654;
  /* text-shadow: 1px 1px 0.5px  gray; */
}
.btn:hover {
  border-radius: "3px";
  box-shadow: "1px 2px 4px  grey";
  text-shadow: "1px 3px 3px  grey";
  transform: scale(1.1);
  transition: 0.3s;
}

button:hover {
  border-radius: "3px";
  box-shadow: "1px 2px 4px  grey";
  text-shadow: "1px 3px 3px  grey";
  transform: scale(1.1);
  transition: 0.3s;
}

/* .denovo {
  box-shadow: 2px 2px 20px 2px;
} */
/* button .btn{
  color:#ffffff;
 background-color:'#0b9490';
} */

.scrollme {
    overflow-x: auto;
}