body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */

  font-family: serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: serif;
}
.title {
  font-family: serif;
  margin-left: 300px;
  /* color: #bd3f32; */
  /* color: #cb356b;
  color: -webkit-linear-gradient(to right, #bd3f32, #cb356b);
  color: linear-gradient(to right, #cb356b, #bd3f32); */
  font-weight: bold;
  text-align: center;
}

.main-sidebar,
.navitem {
  font-family: serif;
  margin-top: 5px;
  font-size: 16px;
  color: #191654;
  /* background: #c95680; 
  background: -webkit-linear-gradient(
    to right,
    #bd3f32,
    #cb356b
  );
  background: linear-gradient(
    to right,
    #bd3f32,
    #cb356b
  ); */
}
/* .nav-item .nav-link p {
  color: white;
} */
/* 
.main-header {
  font-size: 16px;

  background: #c95680;
  background: -webkit-linear-gradient(
    to right,
    #cb356b,
    #bd3f32
  ); 
  background: linear-gradient(to right, #cb356b, #bd3f32);
} */
thead {
  color: #e6e5f1;
  background: 191654;
  background: -webkit-linear-gradient(to right, #0b7874, #0b9490);
  background: linear-gradient(to right, #0b7874, #0b9490);
}
/* button{
  color: white;
  background: 191654;
  background: -webkit-linear-gradient(to right, #0b7874, #0b9490);
  background: linear-gradient(to right, #0b7874, #0b9490);
} */
.content-wrapper {
  font-family: serif;
  font-size: 14px;
}
/* ol li .breadcrumb-item active{
    color: #cb356b; 
} */
/* tr{
  width:"1280px"
}

td{
  width:"50px"
} */