#image
{
    border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150;
  height: 150;
}
#image:hover {
    background-color: black;
  }

  progress[value] {
    width: 50px;
    height: 20px;
  }



  body{
    margin:0;
    padding:0;
  }
  .wrapper1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size:30px;
    font-weight: 900;
    letter-spacing: 2px;
    font-family: nunito;
    /* width: 100px; */
    text-align: center;

  }

  .wrapper1 span{
    text-transform: uppercase;
    animation: 2s linear infinite;
    animation-name: animate;
  }
  .wrapper1 span:nth-child(1){
    animation-delay: 0s;

  }
  .wrapper1 span:nth-child(2){
    animation-delay: 0.1s;
  }
  .wrapper1 span:nth-child(3){
    animation-delay: 0.2s;
  }
  .wrapper1 span:nth-child(4){
    animation-delay: 0.3s;
  }
  .wrapper1 span:nth-child(5){
    animation-delay: 0.4s;
  }
  .wrapper1 span:nth-child(6){
    animation-delay: 0.5s;
  }
  .wrapper1 span:nth-child(7){
    animation-delay: 0.6s;
  }
  .wrapper1 span:nth-child(8){
    animation-delay: 0.7s;
  }
  .wrapper1 span:nth-child(9){
    animation-delay: 0.8s;
  }
  .wrapper1 span:nth-child(10){
    animation-delay: 0.9s;
  }
  .wrapper1 span:nth-child(11){
    animation-delay: 1s;
  }
  .wrapper1 span:nth-child(12){
    animation-delay: 1.1s;
  }
  .wrapper1 span:nth-child(13){
    animation-delay: 1.2s;
  }
  .wrapper1 span:nth-child(14){
    animation-delay: 1.3s;
  }
  .wrapper1 span:nth-child(15){
    animation-delay: 1.4s;
  }
  .wrapper1 span:nth-child(16){
    animation-delay: 1.5s;
  }
  .wrapper1 span:nth-child(17){
    animation-delay: 1.6s;
  }
  var.wrapper1 span:nth-child(18){
    animation-delay: 1.7s;
  }
  @keyframes animate{
    0%{
      filter: blur(0);
    }
    40%{
      filter: blur(20px);
    }
    80%{
      filter: blur(0);
    }
    100%{
      filter: blur(0);
    }
  }



  .accelerated.box {
    transition: transform 500ms;
    will-change: transform;
    background: slateblue;
  }
  .accelerated.box:hover {
    transform: translateY(5px);
  }

  .janky {
    transition: margin-top 500ms;
    will-change: margin-top;
    background: deepskyblue;
  }
  .janky:hover {
    margin-top: 5px;
  }


  #test {
    width: 100px;
    height: 100px;
    background-color: red;
    animation-name: example;
    animation-duration: 4s;
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: yellow;}
  }

  hr.line{
    /* height: 5px;
    background-color: red; */
    /* border: none; */
    /* border-top: 1px dotted red; */
    /* border-top: 1px dashed red; */
    /* border: 10px solid green;
  border-radius: 5px; */
  border-top: 5px dashed red;
}

legend {
  background-color: #000;
  color: #fff;
  padding: 3px 6px;
}

.output {
  font: 1rem 'Fira Sans', sans-serif;
}

input {
  margin: .4rem;
}

/* button shadow */
.shadow {
  width: 140px;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #7CB9E8;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

/* scroll similar */

#gallery {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  display: flex;
}

/* right arrow animate in supplier import process page */

.wrap {
  display: flex;
  /* justify-content: center; */
  /* margin-top: 50px; */
}
.arrow1 {
  /* color:#2E5090; */
  color:#318CE7;
  margin-top: 10px;
}

.arrow1 {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

.backblue
{
  background-color: #7CB9E8;
}

.impstart
{
  background-color: #fff;
  border: 5px solid #0C2340;
  border-right-width: thin;
}

.impinprogress
{
  background-color: #fff;
  border: 5px solid #FFD700;
  border-right-width: thin;
}

.impfinish
{
  background-color: #00FF40;
  border: 5px solid #00FF40;
  border-right-width: thin;
}

.Hoverf:hover {
  background-color: #00FF40;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}


.Hover:hover {
  background-color: #FFD700;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
